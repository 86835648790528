import cryptoRandomString from 'crypto-random-string';

const config = {
    'authorizationEndpoint': 'https://auth.kochid.com/as/authorization.oauth2',
    'tokenEndpoint': 'https://auth.kochid.com/as/token.oauth2',
    'clientId': process.env.REACT_APP_CLIENTID,
    'redirectUri': process.env.REACT_APP_CALLBACK_URL,
    'logoutEndpoint': 'https://auth.kochid.com/idp/startSLO.ping',
    'scopes': 'employeeInfo openid'
};

export const processAuthResults = url => {
    const params = url.split('#')[1];
    const searchParams = new URLSearchParams(params);

    const expiresAt = JSON.stringify((searchParams.get('expires_in') * 1000) + new Date().getTime());
    const acToken = searchParams.get('access_token');
    const idToken = searchParams.get('id_token');

    localStorage.setItem('access_token', acToken);
    localStorage.setItem('id_token', idToken);
    localStorage.setItem('expires_at', expiresAt);

    const state = decodeURIComponent(searchParams.get('state'));
    return state;
}

export function getAuthUrl(originalUrl) {
    var encodedOriginal = encodeURI(originalUrl);
    let nonce = cryptoRandomString({length: 32, type: 'alphanumeric'});

    return config.authorizationEndpoint +
        '?response_type=id_token%20token' +
        '&client_id=' + config.clientId +
        '&scope=' + config.scopes +
        '&redirect_uri=' + config.redirectUri +
        '&nonce=' + nonce +
        '&state=' + encodedOriginal;
}

export const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
}

export function isAuthenticated() {
    let expireTime = localStorage.getItem('expires_at');
    let expiresAt = JSON.parse(expireTime);
    // let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
}

export const getAuthToken = () => {
    if(isAuthenticated() === true) {
        let token = localStorage.getItem('access_token');
        return token;
    }
    else {
        return '';
    }
}