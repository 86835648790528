import React from 'react';
import PropTypes from 'prop-types';

const Header = props => {
    return (
        <div className="py-1" style={{backgroundColor: '#414648'}}>
            <h1 className='display-1 text-center text-white d-none d-md-block'>Koch in {props.state}</h1>
            <h1 className='display-4 text-center text-white d-md-none'>Koch in {props.state}</h1>
        </div>
    );
}

Header.propTypes = {
    state: PropTypes.string.isRequired,
}

export default Header;