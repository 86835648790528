import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import * as auth from '../utils/authHelper';

class AuthenticationCallback extends Component {
    constructor(props) {
        super(props);
        this.redirectTo = auth.processAuthResults(window.location.href);
    }
    
    render() {
        return (
            <Redirect to={this.redirectTo} />
        )
    }
}

export default AuthenticationCallback;