import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

const StatsHeader = props => {
    return (
        <div className="text-center py-3 mb-3" style={{backgroundColor: "#78bf5d"}}>
          <h2 className="mb-0">{props.state} by the Numbers</h2>
          <h5 className="mb-0">as of {moment().format("MMMM YYYY")}</h5>
        </div>
    );
}

StatsHeader.propTypes = {
    state: PropTypes.string.isRequired,
}

export default StatsHeader;