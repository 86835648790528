import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { getActiveStates } from './api';
import * as auth from './utils/authHelper';
import PrivateRoute from './components/PrivateRoute';
import StateSheet from './components/StateSheet';
import StateSelector from './components/StateSelector';
import Login from './components/Login';
import Logout from './components/Logout';
import AuthenticationCallback from './components/AuthenticationCallback';

//for testing
//import { industries, companies } from './testingdata';

class App extends Component {
  state = {
    statesArray: [],
    isLoadingData: false,
    displayLogin: false,
    disableDropdown: false
  }

  getStatesArray = () => {
    //make call to get states from athena
    getActiveStates().then(data => {
      let states = data.results;
      this.setState({
        ...this.state,
        statesArray: states,
        isLoadingData: false,
        disableDropdown: false
      });
    });
  }

  selectorDidMount = () => {
    //if auth, then get states, if not, show login button
    if(auth.isAuthenticated() === true) {
      this.setState({
        ...this.state,
        displayLogin: false,
        isLoadingData: true,
        disableDropdown: true
      });
      this.getStatesArray();
    }
    else {
      this.setState({
        ...this.state,
        displayLogin: true,
        disableDropdown: true
      });
    }
  }

  render() {
    return (
      <BrowserRouter>
        <div>
          <Route exact path="/" render={(props) => <StateSelector {...props} getData={this.selectorDidMount} statesArray={this.state.statesArray} isGettingData={this.state.isLoadingData} displayLogin={this.state.displayLogin} disableDropdown={this.state.disableDropdown}/>} />
          <PrivateRoute path="/state/:stateAbbv" component={StateSheet} />
          <Route path="/login" component={Login}/>
          <Route path="/logout" component={Logout}/>
          <Route path="/authentication/callback" component={AuthenticationCallback}/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
