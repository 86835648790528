import React, { Component } from 'react';
import * as auth from '../utils/authHelper';

export default class Logout extends Component {
    componentDidMount() {
        auth.logout();
        this.props.history.replace('/');
    }

    render() {
        return (
            <p>Logging out...</p>
        );
    }
}