import React, { Component } from 'react';
import { Table, Container, Row, Col } from 'reactstrap';
import numeral from 'numeral';
import Header from './Header';
import StatsHeader from './StatsHeader';
import IndustryList from './IndustryList';
import CompanyList from './CompanyList';
import Statistic from './Statistic';
import { getUSStats, getStateStats, getWorldStats, getStateIndustries, getStateCompanies, getLegendData } from '../api';
import states from '../utils/states.json';
import find from 'lodash/find';
import Legenddata from '../components/LegendData';
import NavBar from './NavBar';

class StateSheet extends Component {
  state = {
    worldHeadcount: "--",
    usHeadcount: "--",
    stateHeadcount: "--",
    stateComp: "--",
    stateDetails: {
      name: "--",
      abbreviation: this.props.match.params.stateAbbv
    }, 
    stateIndustries: [],
    stateCompanies: [],
    legendData: []

  }

  getUSStats(){
    getUSStats().then(stats => {
      let headcount = numeral(stats.results[0].headcount).format();
      this.setState({
        ...this.state,
        usHeadcount: headcount
      });
    });
  }

  getStateStats() {
    getStateStats(this.props.match.params.stateAbbv).then(stats => {
      let headcount = numeral(stats.results[0].headcount).format();
      let comp = numeral(stats.results[0].compensation).format('($0.00 a)');
      this.setState({
        ...this.state,
        stateHeadcount: headcount,
        stateComp: comp
      });
    });
  }

  getStateName() {
    let abbr = this.props.match.params.stateAbbv;
    let details = find(states, o => o.abbreviation === abbr);
    this.setState({
      ...this.state,
      stateDetails: details
    })
  }

  getWorldStats() {
    getWorldStats().then(stats => {
      let headcount = numeral(stats.results[0].headcount).format();
      this.setState({
        ...this.state,
        worldHeadcount: headcount
      });
    });
  }

  getStateIndustries() {
    getStateIndustries(this.props.match.params.stateAbbv).then(industries => {
      this.setState({
        ...this.state,
        stateIndustries: industries.results
      });
    });
  }

  getStateCompanies() {
    getStateCompanies(this.props.match.params.stateAbbv).then(companies => {
      this.setState({
        ...this.state,
        stateCompanies: companies.results
      });
    });
  }

  getLegendData() {
    getLegendData(this.props.match.params.stateAbbv).then(legenddata => {
      this.setState({
        ...this.state,
        legendData: legenddata.results
      });
    });
  }


  checkIfValidState(abbv) {
    //make http call to see if valid
  }

  componentDidMount(){
    this.getUSStats();
    this.getStateName();
    this.getStateStats();
    this.getWorldStats();
    this.getStateIndustries();
    this.getStateCompanies();
    this.getLegendData();
  }

  render() {
    const { usHeadcount, stateHeadcount, stateComp, worldHeadcount } = this.state;
    const stateName = this.state.stateDetails.name;
    return (
      <div>
      <NavBar/>
      <Container>
        <Header state={stateName} />
        
        <Row style={{ marginRight:0 }}>
          <Col md="8" style={{ paddingRight:0}}>
            <img src={process.env.PUBLIC_URL + `/mapImages/${this.state.stateDetails.abbreviation}.png`} alt="Responsive" className="img-fluid w-100"  />
          </Col>
          <Col md="4" style={{ backgroundColor: '#fffff' }} >
          <div  className="h3"></div>
          <Table className="table-sm" style={{ backgroundColor: '#ffffff' }}>
              <thead>
                <tr>
                  {/* <th scope="col">#</th>
                  <th scope="col">Company</th> */}
                </tr>
              </thead>
              <tbody>
              {this.state.legendData.map(counter=> <Legenddata key={counter.pushPinLabel} number = {counter.pushPinLabel}
            name = {counter.companyName} htmlColorCode = {counter.htmlColorCode}  />)}
                </tbody>
            </Table>
            
          </Col>
          {/* <Col md="3">
            <div className="h3">Legend</div>
            <Table className="table-sm">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Company</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Molex</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>KII</td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Molex</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>KII</td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Molex</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>KII</td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Molex</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>KII</td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Molex</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>KII</td>
                </tr>
                <tr>
                  <th scope="row">1</th>
                  <td>Molex</td>
                </tr>
              </tbody>
            </Table>
          </Col> */}
        </Row>
        
        <StatsHeader state={stateName} />
        <Row>
          <Col md="6">
            <Statistic statValue={worldHeadcount} statDescription="Employees Worldwide" />
          </Col>
          <Col md="6">
            <Statistic statValue={usHeadcount} statDescription="US Employees" />
          </Col>
          <Col md="6">
            <Statistic statValue={stateHeadcount} statDescription={`Employees working in ${stateName}`} />
          </Col>
          <Col md="6">
            <Statistic statValue={stateComp} statDescription={`Wages paid in ${stateName}`} />
          </Col>
        </Row>

        <div className="my-1 p-2" style={{ backgroundColor: '#4db5d0' }}>
          <h3 className="text-center">Industry Areas in {stateName}</h3>
          <IndustryList industries={this.state.stateIndustries} />
        </div>

        <div className="text-center my-1">
          <p style={{ fontSize: '1.5em', }}><b>About Koch Industries</b></p>
          <p>What is now Koch Industries, Inc. began as Wood River Oil and Refining Co. in 1940. Based in Wichita, Kansas, Koch Industries is one of the largest private companies in America. Today, Koch companies manufacture a wide variety of products, including transportation fuels, building and consumer products, electronic connectors, fibers, fertilizers, membrane filtration and pollution-control equipment. </p>
        </div>

        <h4 className="mb-4 alwaysbreak">Koch Companies in {stateName}</h4>
        <CompanyList companies={this.state.stateCompanies} />

        <img src={process.env.PUBLIC_URL + `/images/footer.svg`} alt="Responsive" className="img-fluid w-100"  />
      </Container>
      </div>
    );
  }
}

export default StateSheet;