import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Jumbotron, Container, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Fade } from 'reactstrap';
import find from 'lodash/find';
import result from 'lodash/result';
import NavBar from './NavBar';


class StateSelector extends Component {
    state = {
        dropdownOpen: false
    }

    toggleDropdown = () => 
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });

    selected = (e) => {
        let state = e.target.innerText;
        let abbv = result(find(this.props.statesArray, o => o.name === state), 'abbreviation');
        let path = `state/${abbv}`;
        this.props.history.push(path);
    }

    componentDidMount() {
        this.props.getData();
    }
    
    render() {
        return (
            <div>
            <NavBar/>
            <Container>
            <Jumbotron >
                <h1 className="display-4">Welcome to the State Facts Sheet Creator!</h1>
                <p className="lead">After logging in from the menu above, please select a state from the dropdown below to create a facts sheet. Once created, you can print the page using your browsers print functionality. </p>
                <hr className="my-3" />
                <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle caret color="primary" disabled={this.props.disableDropdown}>
                        Select State
                    </DropdownToggle>
                    <DropdownMenu className="stateSelectorScroll">
                        <DropdownItem header>States</DropdownItem>
                        {this.props.statesArray.map((state, index) => <DropdownItem key={index} onClick={this.selected}>{state.name}</DropdownItem>)}
                    </DropdownMenu>
                </Dropdown>
                <Fade in={this.props.isGettingData}>
                    Loading...
                </Fade>
                <Fade in={this.props.displayLogin}>
                    Please log in to view a list of current states.
                </Fade>
            </Jumbotron>
            </Container>
            </div>
        );
    }
}

StateSelector.propTypes = {
    statesArray: PropTypes.array.isRequired,
    isGettingData: PropTypes.bool.isRequired,
    displayLogin: PropTypes.bool.isRequired,
    disableDropdown: PropTypes.bool.isRequired,
    getData: PropTypes.func.isRequired
}

export default StateSelector;