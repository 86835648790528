import axios from 'axios';
import * as auth from './utils/authHelper';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const getUSStats = () => {
    return axios.get(`${BASE_URL}/usstatistics`, {
        headers: {
            'Authorization': auth.getAuthToken()
        }
    }).then(res => res.data).catch(err => console.log(err));
}

const getStateStats = (abbv) => {
    return axios.get(`${BASE_URL}/statestatistics/${abbv}`, {
        headers: {
            'Authorization': auth.getAuthToken()
        }
    }).then(res => {
        return res.data
    }).catch(err => console.log(err));
}

const getWorldStats = () => {
    return axios.get(`${BASE_URL}/worldstatistics`, {
        headers: {
            'Authorization': auth.getAuthToken()
        }
    }).then(res => res.data).catch(err => console.log(err));
}

const getActiveStates = () => {
    return axios.get(`${BASE_URL}/activestates`, {
        headers: {
            'Authorization': auth.getAuthToken()
        }
    }).then(res => res.data).catch(err => {
        console.log(err)
    });
}

const getStateIndustries = (abbv) => {
    return axios.get(`${BASE_URL}/stateindustries/${abbv}`, {
        headers: {
            'Authorization': auth.getAuthToken()
        }
    }).then(res => {
        return res.data
    }).catch(err => console.log(err));
}

const getStateCompanies = (abbv) => {
    return axios.get(`${BASE_URL}/statecompanies/${abbv}`, {
        headers: {
            'Authorization': auth.getAuthToken()
        }
    }).then(res => {
        return res.data
    }).catch(err => console.log(err));
}
// to import legend data details from backend api 
const getLegendData = (abbv) => {

   return axios.get(`${BASE_URL}/legenddata/${abbv}`,
     {
        headers:{
            'Authorization': auth.getAuthToken()
                }
     }
    ).then(res => {
        return res.data
    }).catch(err => console.log(err));
}

export {
    getUSStats,
    getStateStats,
    getWorldStats,
    getActiveStates,
    getStateIndustries,
    getStateCompanies,
    getLegendData
};