import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import * as auth from '../utils/authHelper';

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (
        auth.isAuthenticated() === true
            ? <Component {...props} {...rest} />
            : <Redirect to={{
                pathname: '/login',
                state: {from: props.location }
            }} />
    )} />
)

export default PrivateRoute;