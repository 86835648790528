import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';

const Company = props => {
    return (
        <li className="breakme">
            <Row>
                <Col xs="12" className="text-center text-md-left d-md-none">
                    <img src={props.companyIcon} alt={props.companyName} className="w-25" />
                </Col>
                <Col md="2" className="text-center text-md-left d-none d-md-block align-self-center">
                    <img src={props.companyIcon} alt={props.companyName} className="img-fluid" />
                </Col>
                <Col md="10" className="align-self-center">
                    <div>
                        <h4>{props.companyName}</h4>
                        <p>{props.companyDescription}</p>
                    </div>
                </Col>
            </Row>
        </li>
    );
}

Company.propTypes = {
    companyName: PropTypes.string.isRequired,
    companyDescription: PropTypes.string.isRequired,
    companyIcon: PropTypes.string.isRequired
}

export default Company;