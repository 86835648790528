import React, { Component } from 'react';
import * as auth from '../utils/authHelper';

class Login extends Component {
    componentDidMount() {
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        window.location = auth.getAuthUrl(from.pathname);
    }

    render() {
        return (
            <section>Redirecting to SSO...</section>
        )
    }
}

export default Login;