import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardTitle, CardText } from 'reactstrap';

const Statistic = props => {
    return (
        <Card body className="text-center">
            <CardTitle tag="h1" className="mb-0">{props.statValue}</CardTitle>
            <CardText tag="h4">{props.statDescription}</CardText>
        </Card>
    );
}

Statistic.propTypes = {
    statValue: PropTypes.string.isRequired,
    statDescription: PropTypes.string.isRequired
}

export default Statistic;