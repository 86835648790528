import React from "react";

const Legenddata = props => {
  return (
    <tr>
      <th scope="row"> 
      <svg width="30" height="30">
                <circle className="align-self-center"  cx={30/2} cy={30/2} r="10" fill={props.htmlColorCode} />
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  fill="white"
                  dy=".3em"
                >
                {props.number}
                </text>
                Sorry, your browser does not support inline SVG.
              </svg>
    </th>
    <td>
    
      </td> 
      <td> {props.name}</td>
    </tr>
  );

}

export default Legenddata;