import React from 'react';
import PropTypes from 'prop-types';

import Company from './Company';

const CompanyList = props => {
    return (
        <ul className="list-unstyled">
            {props.companies
                .map((company, index) => 
                <Company key={index} companyName={company.displayname} companyDescription={company.description} companyIcon={process.env.PUBLIC_URL + "/images/" + company.business_group + ".svg"} />
            )}
        </ul>
    );
}

CompanyList.propTypes = {
    companies: PropTypes.array.isRequired,
}

export default CompanyList;