import React from 'react';
import PropTypes from 'prop-types';

const IndustryList = props => {
    return (
        <ul className="list-inline text-center">
            {props.industries
                .map((industry, index) => 
                <li key={index} className="list-inline-item">{industry.industrycode}</li>
            )}
        </ul>
    );
}

IndustryList.propTypes = {
    industries: PropTypes.array.isRequired,
}

export default IndustryList;