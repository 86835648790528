import React from 'react';
import { Navbar, NavbarBrand, Nav, NavLink, NavItem, NavbarToggler, Collapse } from 'reactstrap';
import * as auth from '../utils/authHelper';
import { Link } from 'react-router-dom';

class NavBar extends React.Component {
    state = {
        isOpen: false
    }

    toggle = () => {
        this.setState({
            ...this.state,
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <Navbar color="dark" dark className="mb-3" expand="md">
                <NavbarBrand tag="span">State Facts Sheet</NavbarBrand>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem>
                            <NavLink tag={Link} to="/" style={{ color: "white" }}>Home</NavLink>
                        </NavItem>
                        <NavItem>
                            {auth.isAuthenticated() === true ? (
                                <NavLink tag={Link} to="/logout" style={{ color: "white" }}>Logout</NavLink>
                            ) : (
                                    <NavLink tag={Link} to="/login" style={{ color: "white" }}>Login</NavLink>
                                )}
                        </NavItem>
                    </Nav>
                </Collapse>
            </Navbar>
        );
    }
}

export default NavBar;